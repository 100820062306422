import logo from './logo.svg';
import './App.css';
import history from './History';
import OurHistory from './Pages/OurHistory';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className=''>
    <BrowserRouter history={history}>
      <div id="App" className="grid-container">
   
        <Routes>
          <Route exact path="/" element={<OurHistory/>} >
          </Route>
        </Routes>
     
      </div>
    </BrowserRouter>
  </div>
  );
}

export default App;
