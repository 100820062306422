
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const OurHistory = () =>{
 return <div>

<h1 className="titles">Some of the many memories that made us us!</h1>
<h3 className="titles">I love you Bianca</h3>
<VerticalTimeline>
<VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Novembern 12th 2016"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <h3>We started Dating!</h3>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665936743/IMG_0858.jpg'></img>
    <p>   
      Same day you also visited my home town.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="November 2016"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <h3>Our first proper date, ice skating!</h3>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665926824/IMG_0877.jpg'></img>
    <p>   
      Where you fell over and pulled me with you.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="April 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <h3 className="vertical-timeline-element-title">We went to Paris!</h3>
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665927351/IMG_1565.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665927849/IMG_1729.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666040768/IMG_2124.jpg'></img>
    <p>
      We had to eat a lot of Asda sausages and mashed potatoes to get here. What a great time we had, even if we did have to take a bus there and back.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="May 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <h3 className="vertical-timeline-element-title">Scotland</h3>
    <img width="230px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665927858/IMG_1978.jpg'></img>
    <img width="170px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666041296/IMG_2081.png'></img>   
    <p>
      Our first time to Scotland, Glasgow sucked, but we loved Edinburgh. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="End of June until August 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666041526/IMG_2128.png'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666041487/IMG_2216.jpg'></img>
    <h3 className="vertical-timeline-element-title">Our first long distance :(</h3>
    <p>
      Not so fun times, but you allways managed to make me laugh from so far away still.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="My 22nd Birthday"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665927882/IMG_2265.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928065/IMG_2298.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928139/IMG_2327.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665927945/IMG_2272.jpg'></img>
    <h3 className="vertical-timeline-element-title">America!</h3>
    <p>
      First time in the Americas and us finally reunited after so long apart.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="October 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928577/IMG_2649.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928614/IMG_2668.jpg'></img>
    <h3 className="vertical-timeline-element-title">Brighton</h3>
    <p>
      We went to Brighton for a day 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Mid October 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666042468/IMG_2720.jpg'></img>  
    <h3 className="vertical-timeline-element-title">A Toblerone</h3>
    <p>
      Little did we know we would one day go on to tackle such a mighty toblerone together. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Halloween 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928858/IMG_2751.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928678/IMG_2745.jpg'></img>   
    <h3 className="vertical-timeline-element-title">Halloween!</h3>
    <p>
      Woah spooky!
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928895/IMG_2790.jpg'></img>    
    <h3 className="vertical-timeline-element-title">1 Year anniversary</h3>
    <p>
      Chef Bianca at work
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Christmas 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928948/20171125_195515_HDR.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665928944/20171125_195404_HDR.jpg'></img> 
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666043264/20171125_180903_HDR.jpg'></img>  
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666043661/20171211_122311_HDR.jpg'></img> 
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929131/20180113_174610_HDR.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Its Christmas</h3>
    <p>
      Just two christmas cool cats, you make Christmas so fun!
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="December 2017"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929034/20171203_192328.jpg'></img>   
    <h3 className="vertical-timeline-element-title">First time to winter wonderland</h3>
    <p>
    
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="March 2018"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929459/20180317_171518_HDR.jpg'></img>  
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929462/20180317_171051_HDR.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929468/20180317_212839_HDR.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666044226/20180317_154037_HDR.jpg'></img> 
    <h3 className="vertical-timeline-element-title">We went to Stockholm</h3>
    <p>
        Chilly chilly, lots of walking and coffee. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="April 2018"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929759/20180426_113201_HDR.jpg'></img>  
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929762/20180426_113239_HDR.jpg'></img>
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929765/20180424_184634_HDR.jpg'></img> 
    <img width="300px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929769/20180424_184344_HDR.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665929777/20180424_114840_HDR.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930031/20180428_153831_HDR.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Greece Lightning</h3>
    <p>
        Climbed a volcano in sandels, watched einer throw up and had a fight about cats. Can't wait for us to go back one day   
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="May 2018"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930210/20180505_213830_HDR.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930214/20180503_201510_HDR.jpg'></img>
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666046768/32950268_10214859235128349_2248166687964659712_n.jpg'></img>
   
    <h3 className="vertical-timeline-element-title">Biancas 21st Birthday</h3>
    <p>
        You slayed the day away!   
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="End of May 2018"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930270/20180525_155452_HDR.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666046986/34342303_10214970745996051_3532482752668499968_n.jpg'></img>     
    <h3 className="vertical-timeline-element-title">Paris round 2</h3>
    <p>
        I had a terrible bus ride, great time once there though. Remember the airbnb leaving you a bad rating lol.  
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2018"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930373/20181110_181634_HDR.jpg'></img>  
    <h3 className="vertical-timeline-element-title">Milan</h3>
    <p>
        The shortest holiday ever of about 4 hours lol.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="December 2018 / New year"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666045938/20181227_172236_HDR.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930523/20190102_221448_HDR.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930519/20181231_211553_HDR.jpg'></img> 
    <h3 className="vertical-timeline-element-title">America V2 / New year</h3>
    <p>
        The best new years ever. Had so much fun with you and your family.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="May 2019"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666047312/60325502_10217581446021920_8564368120565727232_n.jpg'></img>  
    <h3 className="vertical-timeline-element-title">Ibiza</h3>
    <p>
        Probably one of the best group holidays we went on. Lots of laughs and funny stories. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="June 2019"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666047224/61925449_10217729874892549_8856344051796934656_n.jpg'></img>  
    <h3 className="vertical-timeline-element-title">Graduation ball</h3>
    <p>
        
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="January 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666049007/83763707_10219788486676557_5998810655166038016_n.jpg'></img>  
    <h3 className="vertical-timeline-element-title">Iceland</h3>
    <p>
        The coolest most fun road trip ever, just me and you out in the wilderness with so many mountains.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Febuary 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666058894/Screenshot_2022-10-17_220740.png'></img>  
    <h3 className="vertical-timeline-element-title">Dublin</h3>
    <p>
        Some super fun times, for sure better experience than belfast. That guiness factory was a total bore though, especially when hungover. P.S we miss you Lewis
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="August 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1665930780/IMG_8611.jpg'></img>  
    <h3 className="vertical-timeline-element-title">We got engaged!</h3>
    <p>
        One of the best days of my life, the day you said yes. Was strange how even with all the people watching and cheering it felt like no one but me and you in that moment. When you said yes you made me the happiest man alive, knowing I would get married to the most beautiful, funny, goofy person alive.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="October 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666132672/IMG_20201011_153904_Bokeh.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666132626/131589735_10222835080999511_1062003827253932946_n.jpg'></img>  
    <h3 className="vertical-timeline-element-title">Rye</h3>
    <p>
        A fun little date day, very relaxed and chill. Loved getting the tea watching the weird amount of birds popping in and out the bush. Then we went to beach and cuddled and took some fun pics.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="October 2020"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666059914/IMG_20201223_195851.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666059677/IMG_20201223_195902.jpg'></img> 
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666059858/IMG_20201222_164021.jpg'></img>  
    <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666059765/IMG_20201122_135150.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Christmas 2020</h3>
    <p>
        Another super cool us Chirstmas, fair to say you beat me that year on the ginger bread houses
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Jan 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
    <h3 className="vertical-timeline-element-title">New years 2021</h3>
    <p>
        A lockdown new years at London Bridge, remember all the tick tock comments lol.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="First half of 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666133118/IMG_20210209_225542_1.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Another long distance lockdown gap</h3>
    <p>
        This was a hard one, think it was the longest and for sure the hardest. You had so much fun working at codehunter while I was enjoying some of the strict UK lockdowns in my flat. Even far apart we still had eachother though.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="September 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666133307/IMG_20210905_115320.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Belfast</h3>
    <p>
        We went to Belfast where the best attraction was a museum for the titanic because they helped build it lol. The best fun was when we left belfast on our game of thrones tour.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="September 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666133581/IMG_20210911_144526.jpg'></img> 
    <h3 className="vertical-timeline-element-title">A Palace day out</h3>
    <p>
        We went to really cool palace, had some coffee, chilled a little and you poked a statue in the buthole. We then went to the cotsworlds which was a bit overated. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="October 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  >  
    <h3 className="vertical-timeline-element-title">Halloween</h3>
    <p>
        We hosted an awesome halloween party in our tiny Welwyn studio flat. Everyone got super smashed and some drama occured but it was great fun.
    </p>
  </VerticalTimelineElement>
 
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="Christmas 2021"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666134201/IMG_20211219_201311.jpg'></img> 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666134532/269308920_10225347961059942_8213733709379701538_n.jpg'></img> 
    <h3 className="vertical-timeline-element-title">Christmas 2021</h3>
    <p>
        My gingerbread house was a little better than the previous one this year. Was a good christmas, especially with the fact we had the coolest christmas trees. Was sad for us though to have to be apart on another christmas day and for new years :(
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="January 2022"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666134667/275044199_10225720298568147_719777217941248315_n.jpg'></img> 
    <h3 className="vertical-timeline-element-title">America, Miami</h3>
    <p>
       Third time in the Americas. Had so much fun going to Miami with you. Had some great laughs and memorys made.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date= "May 2022"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666135075/Screenshot_2022-10-18_191731.png'></img> 
    <h3 className="vertical-timeline-element-title">Portugal</h3>
    <p>
       Probably one of the most chillest holidays we had together. Cant beleive how empty everywhere was. Had so much fun driving around portugal and chilling on beaches with you. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date= "August - October 2022"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666135520/IMG_20221008_072357.jpg'></img> 
    <h3 className="vertical-timeline-element-title">London flat</h3>
    <p>
       3 months in the london flat, although really tight with all our stuff in there and working from home together we still had a great time.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date= "October 2022"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={""}
  > 
   <img width="200px" src='https://res.cloudinary.com/dng8alzfo/image/upload/v1666135366/IMG_20221014_200925.jpg'></img> 
    <h3 className="vertical-timeline-element-title">The big move</h3>
    <p>
       We made it to America and now we are getting married!
    </p>
  </VerticalTimelineElement>
</VerticalTimeline>
 </div>
}
export default OurHistory